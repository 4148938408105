
import '@/assets/styles/imports/dtc.sass'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { TaskInterface } from '@/interfaces/task'
import VueI18n from 'vue-i18n'
import TranslateResult = VueI18n.TranslateResult
import storage from '@/storage'

@Component
export default class Dtc extends Vue {
  @Prop(Array) readonly errors!: Array<TaskInterface.Dtc>
  @Prop({ type: Number, default: 0 }) readonly price!: number
  @Prop({ type: String, default: '₽' }) readonly currency!: string
  @Prop({ type: String, default: '' }) readonly billingDescription!: string
  @Prop({ type: String, default: '' }) readonly billingNote!: string

  search = ''
  autoCompleteList: Array<TaskInterface.Dtc> = []
  indexFocus = 0

  cleanSearchText () {
    this.search = ''
  }

  searchError () {
    if (!this.search) return
    const autoCompleteList: Array<TaskInterface.Dtc> = []
    const search = new RegExp(this.search.toUpperCase())
    for (let i = 0, len = this.errors.length; i < len; i++) {
      const error = this.errors[i]
      if (error.isSel) continue
      if (!search.test(error.dtc.toUpperCase())) continue
      autoCompleteList.push(error)
      if (autoCompleteList.length >= 10) break
    }
    this.autoCompleteList = autoCompleteList
  }

  addError (id: number) {
    const error = this.errors.find(error => error.id === id)
    if (error) error.isSel = true
    this.cleanSearchText()
    this.$emit('change-errors')
  }

  removeError (id: number) {
    const error = this.errors.find(error => error.id === id)
    if (error) error.isSel = false
    this.$emit('change-errors')
  }

  addEventArrow () {
    this.indexFocus = 0
    window.addEventListener('keydown', this.eventArrow)
  }

  removeEventArrow () {
    this.indexFocus = 0
    window.removeEventListener('keydown', this.eventArrow)
  }

  eventArrow (event: KeyboardEvent) {
    if (event.key === 'ArrowUp') {
      this.indexFocus = this.indexFocus - 1 < 1 ? this.autoCompleteList.length : this.indexFocus - 1
    } else if (event.key === 'ArrowDown') {
      this.indexFocus = this.indexFocus + 1 > this.autoCompleteList.length ? 1 : this.indexFocus + 1
    } else if (event.key === 'Enter') {
      if (this.autoCompleteList.length) this.addError(this.autoCompleteList[this.indexFocus - 1].id)
    }
  }

  get login (): boolean {
    return !!storage.user
  }

  get checkedDtcErrors (): Array<TaskInterface.Dtc> {
    return this.errors.filter(error => error.isSel)
  }

  get noteText (): TranslateResult {
    return this.checkedDtcErrors.length ? this.$t('selectedFill') : this.$t('selectedEmpty')
  }

  get total (): string {
    if (this.price) return `${this.price} ${this.currency}`
    return ''
  }
}
