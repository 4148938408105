
//:cost="data.price"
//:desc="getDesc()"
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import getLangIdObject from "@/additionally/getLangIdObject";
import storage from "@/storage";
import ModalSubscription from "@/components/_modal/ModalSubscription.vue";
import api from "@/api";
import ModalSuccessSubscription from "@/components/_modal/ModalSuccessSubscription.vue";
import ModalDownload from "@/components/_modal/ModalDownload.vue";
import ModalProcess from "@/components/_modal/ModalProcess.vue";

@Component({
  components: {
    ModalProcess,
    ModalSubscription,
    ModalSuccessSubscription,
    ModalDownload,
  },
})
export default class ResultPanel extends Vue {
  @Prop({ type: Object, default: null }) readonly task!: any;
  @Prop({ type: String, default: "₽" }) readonly currency!: string;
  @Prop({ type: Number, default: 0 }) readonly price!: number;
  @Prop({ type: Number, default: 1 }) readonly discount!: number;
  @Prop({ default: false }) readonly isPricePage!: boolean;
  @Prop({ type: Boolean, default: false }) readonly noFile!: boolean;
  @Prop({ default: false }) forceResultPanelUpdate!: boolean;
  @Prop({ default: false }) isAddOrder!: boolean;

  isOnlyBuy = false;
  isAvailableSubscription = false;
  isSubscribed = false;
  isLimitSubscription = false;
  promoCode = "";
  showSubscriptionModal = false;
  showSubscriptionSuccess = false;
  isAuth = false;
  downloadToken: any = null;
  showDownloadModal = false;
  isShowProcessFile = false;
  isShowModalError = false;

  get isEmptyForm(): any {

    if (
      (this.task.patches.find((item: any) => item.isSelected) && !this.isAddOrder) ||
      this.task.taskDtcCurrent.items.find((item: any) => item.isSel)
    ) {
      return false;
    }

    return true;
  }

  @Watch("task")
  watchchengedTask() {
    this.changeTask();
  }

  changeTask() {
    if (this.task.isSubscriptionAvailable === false) {
      this.isOnlyBuy = true;
    } else if (this.task.isUserSubscribed && !this.task.isUserLimitEnd) {
      this.isSubscribed = true;
    } else if (this.task.isUserSubscribed && this.task.isUserLimitEnd) {
      this.isLimitSubscription = true;
    } else if (
      this.task.isSubscriptionAvailable &&
      this.task.isUserSubscribed == false
    ) {
      this.isAvailableSubscription = true;
    }
  }

  getSolution(): any {
    this.isShowProcessFile = true
    this.$emit("save-task", {
      done: () => {
        api.subscription.processTask(this.task.taskId).then((response: any) => {
          if (response) {
            api.task.getDownloadLink(this.task.taskId).then((response: any) => {
              this.isShowProcessFile = false;
              this.downloadToken = response;
              this.showDownloadModal = true;
              //api.firmware.downloadFile(response);
            });
          }
        });
      },
    });
  }

  closeDownloadModal() {
    this.showDownloadModal = false;
  }

  getSubscriptionDesc(): void {
    return getLangIdObject(this.task.subscription.subscriptionInfo).descr;
  }

  checkCode() {
    if (this.promoCode.length !== 5 && this.promoCode.length) return;
    this.eventChangePromoCode();
  }

  eventChangePromoCode() {
    this.$emit("change-promo-code", this.promoCode);
  }

  openSubscriptionModal() {
    this.showSubscriptionModal = true;
  }

  closeSubscriptionModal() {
    this.showSubscriptionModal = false;
  }

  closeSubscriptionSuccessModal() {
    this.showSubscriptionSuccess = false;
  }

  payment(): void {
    this.openSubscriptionModal();
  }

  startProdamusPayment () {
    this.$emit('start-prodamus-payment')
  }

  cancelProdamusPayment () {
    this.$emit('cancel-prodamus-payment')
  }

  prodamusError () {
    this.$emit('prodamus-error')
    this.closeSubscriptionModal()
  }

  orderError () {
    this.$emit('prodamusError')
    this.closeSubscriptionModal()
  }

  get finalResult(): string {
    return `${this.price - this.discount} ${this.currency}`;
  }

  get subscriptionId(): any {
    return this.task.subscription.subscriptionId;
    //return 11;
  }

  get subscriptionPrice(): any {
    return this.task.subscription.price;
  }

  buyTask() {
    if (this.price && !this.noFile) this.$emit("buy-task");
  }

  //get isAuth(): boolean {
  //  return !!storage.user;
  //}

  async created() {
    this.changeTask();
    this.isAuth = await api.authorize.isAuthenticated();
  }
}
