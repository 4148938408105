
import '@/assets/styles/imports/modal-download.sass'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { TaskInterface } from '@/interfaces/task'
import Patch = TaskInterface.Patch
import storage from '@/storage'
import getLangId from '@/additionally/getLangId'
import api from '@/api'

@Component
export default class ModalDownload extends Vue {
  @Prop({ type: String }) readonly token!: string
  @Prop({ type: Boolean, default: false }) readonly isSecondTitle!: boolean
  @Prop({ type: Array, default: () => [] }) readonly patches!: Array<Patch>
  langId = 1

  getLanguage () {
    this.langId = getLangId()
  }

  getNote (infos: Array<{ langId: number; descr: string }>): string {
    const info = infos.find(info => info.langId === this.langId)
    if (info) return info.descr
    return ''
  }

  async downloadFile () {
    await api.firmware.downloadFile(this.token)
  }

  eventClose () {
    this.$router.push({name: "History", params: { lang: this.$route.params.lang }})
    //this.$emit('close');
  }

  get link (): string {
    return `${window.location.origin}/download/${this.token}`
  }

  get mail () {
    return storage.user?.email
  }

  get notes (): Array<Patch> {
    return this.patches.filter((patch: Patch) => patch.isSelected && patch.infos.length )
  }

  created () {
    this.getLanguage()
    storage.lastPaymentId = null
  }
}
