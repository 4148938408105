
import '@/assets/styles/imports/patches.sass'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { TaskInterface } from '@/interfaces/task'
import getLangId from '@/additionally/getLangId'
import Patch = TaskInterface.Patch

@Component
export default class Patches extends Vue {
  @Prop(Array) readonly patches!: Array<Patch>
  @Prop({ type: Number, default: 0 }) readonly price!: number
  @Prop({ type: String, default: '$' }) readonly currency!: string
  langId = 1
  timers = 0

  eventChangeList () {
    ++this.timers
    setTimeout(() => {
      --this.timers
      if (!this.timers) this.$emit('change-patch')
    }, 500)
  }

  getNote (infos: Array<{ langId: number; descr: string }>): string {
    const info = infos.find(info => info.langId === this.langId)
    if (info) return info.descr
    return ''
  }

  getLanguage () {
    this.langId = getLangId()
  }

  get checkedPatchesNames (): string {
    const checkedList: Array<string> = []
    for (let i = 0, len = this.patches.length; i < len; i++) {
      const patch = this.patches[i]
      if (patch.isSelected) checkedList.push(patch.name)
    }
    if (!checkedList.length) return `${this.$t('selectedEmpty')}`
    return `${this.$t('selectedFill')}: ${checkedList.join(', ')}`
  }

  get total (): string {
    if (this.price) return `${this.price} ${this.currency}`
    return ''
  }

  created () {
    this.getLanguage()
  }

  beforeUpdate () {
    this.getLanguage()
  }
}
